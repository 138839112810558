<template>
  <div>
    <div id="section-content" class="container container-fluid">
      <form @submit.prevent="submit" class="form-login form-horizontal" id="add_numbersets" role="form">
        <input type="hidden" name="add_numbersets" value="1" />
        <div class="bar-back">
          <router-link to="/member/numbersets"><i class="fas fa-chevron-left"></i> จัดการเลขชุด</router-link>
        </div>
        <div
          class="p-2 w-100 bg-light main-content align-self-stretch"
          style="min-height: calc((100vh - 179px) - 50px)"
        >
          <div
            class="
              bgwhitealpha
              text-secondary
              shadow-sm
              rounded
              p-2
              px-2
              xtarget
              col-lotto
              d-flex
              flex-row
              justify-content-between
              mb-1
              pb-0
            "
          >
            <div class="lotto-title">
              <h4><i class="fas fa-plus"></i> สร้างเลขชุด</h4>
            </div>
          </div>
          <div class="bgwhitealpha text-secondary shadow-sm rounded pt-2 px-2 pb-0 xtarget col-lotto">
            <div class="row px-2 align-items-center">
              <div class="col-12 col-sm-12 col-md-2 pr-1">
                <label><i class="fas fa-list-ol"></i> ชื่อชุด</label>
              </div>
              <div class="col-12 col-sm-12 col-md-8 px-1">
                <input
                  name="title"
                  type="text"
                  placeholder="ระบุชื่อเรียกเลขชุด"
                  class="form-control form-control-sm"
                  required=""
                  v-model="setName"
                />
              </div>
              <div class="col-2 pl-1"></div>
              <div class="w-100 border-bottom my-3"></div>
              <div class="col-12">
                <label><i class="fas fa-plus-square"></i> สร้างชุดตัวเลข</label>
              </div>
              <div class="col-12">
                <ol class="pl-2" id="add_numbersets_list">
                  <li class="mb-2">
                    <div class="row">
                      <div class="col-5 col-sm-5 col-md-3 pr-1">
                        <select
                          class="form-control dropdown-numberset valid"
                          aria-invalid="false"
                          data-style="btn-outline-primary btn-sm"
                          v-model="type"
                          @change="value=''"
                        >
                          <option :value="0">3ตัวบน</option>
                          <option :value="1">3ตัวโต๊ด</option>
                          <option :value="2">3ตัวหน้า</option>
                          <option :value="3">3ตัวล่าง</option>
                          <option :value="4">2ตัวบน</option>
                          <option :value="5">2ตัวล่าง</option>
                          <option :value="6">วิ่งบน</option>
                          <option :value="7">วิ่งล่าง</option>
                        </select>
                      </div>
                      <div class="col-5 col-sm-5 col-md-7 px-1">
                        <input
                          type="tel"
                          class="form-control form-control"
                          :pattern="`[0-9]{${getLength(type),getLength(type)}}`"
                          :maxlength="getLength(type)"
                          required
                          v-model="value"
                          oninput="this.value=this.value.replace(/[^0-9]/g,'')"
                        />
                      </div>
                      <div class="col-2 col-sm-2 col-md-2 pl-1">&nbsp;</div>
                    </div>
                  </li>
                  <li class="mb-2" v-for="(item, index) in list" :key="index">
                    <div class="row" style="align-item: center">
                      <div class="col-5 col-sm-5 col-md-3 pr-1">
                        <select
                          class="form-control dropdown-numberset valid"
                          aria-invalid="false"
                          data-style="btn-outline-primary btn-sm"
                          v-model="item.type"
                          @change="item.value=''"
                        >
                          <option :value="0">3ตัวบน</option>
                          <option :value="1">3ตัวโต๊ด</option>
                          <option :value="2">3ตัวหน้า</option>
                          <option :value="3">3ตัวล่าง</option>
                          <option :value="4">2ตัวบน</option>
                          <option :value="5">2ตัวล่าง</option>
                          <option :value="6">วิ่งบน</option>
                          <option :value="7">วิ่งล่าง</option>
                        </select>
                      </div>
                      <div class="col-5 col-sm-5 col-md-7 px-1">
                        <input
                          type="tel"
                          class="form-control form-control"
                          :pattern="`[0-9]{${getLength(item.type),getLength(item.type)}}`"
                          :maxlength="getLength(item.type)"
                          v-model="item.value"
                          required
                          oninput="this.value=this.value.replace(/[^0-9]/g,'')"
                        />
                      </div>
                      <div class="col-2 col-sm-2 col-md-2 pl-1">
                        <button class="btn btn-outline-danger btn-sm" @click="remove(item)">
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    </div>
                  </li>
                </ol>
              </div>
              <div class="col-12 table-primary py-2 rounded-bottom">
                <button class="btn btn-outline-primary btn-block" type="button" @click="addColumns()">
                  <i class="fas fa-plus-circle"></i> เพิ่มชุดตัวเลข
                </button>
              </div>
            </div>
          </div>
          <div class="bg-white p-2 rounded shadow-sm w-100 mb-5">
            <div class="row">
              <div class="col pr-1">
                <button class="btn btn-secondary btn-block" type="reset">ยกเลิก</button>
              </div>
              <div class="col pl-1">
                <button class="btn btn-success btn-block" type="submit"><i class="fas fa-save"></i> บันทึก</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { setNumberSet } from '@/services'
export default {
  methods: {
    ...mapActions(['setLoading']),
    addColumns () {
      this.list.push({
        type: 0,
        value: ''
      })
    },
    remove (item) {
      const index = this.list.indexOf(item)
      if (index !== -1) {
        this.list.splice(index, 1)
      }
    },
    async submit () {
      try {
        this.setLoading(true)
        this.numberSets[this.type] = [String(this.value)]
        this.list.map(e => this.numberSets[e.type].push(String(e.value)))
        this.numberSets = this.numberSets.map(e => this.$_.uniq(e))
        const payload = {
          setName: this.setName,
          setNumber: this.numberSets
        }
        const resp = await setNumberSet(payload)
        if (resp.status_code === 'SUCCESS') {
          this.$router.replace('/member/numbersets')
        }
        this.setLoading(false)
      } catch (error) {
        this.setLoading(false)
      }
    },
    getLength (value) {
      return Number(value) === 6 || value === 7 ? 1 : value === 4 || value === 5 ? 2 : 3
    }
  },
  data () {
    return {
      indexType: ['สามตัวบน', 'สามตัวโต๊ด', 'สามตัวหน้า', 'สามตัวล่าง', 'สองตัวบน', 'สองตัวล่าง', 'วิ่งบน', 'วิ่งล่าง'],
      list: [],
      setName: '',
      type: 0,
      value: '',
      numberSets: [[], [], [], [], [], [], [], []]
    }
  }
}
</script>

<style>
</style>
